<template>
  <div class="teacher-detail">
    <Promised :promise="loading">
      <template v-slot:pending>
        <loading-spinner />
      </template>
      <template v-slot>
        <div v-if="teacherDetail">
          <div class="row">
            <div class="col-6">
              <div>
                <h1>{{ teacherDetail.summary.firstName }} {{ teacherDetail.summary.lastName }}</h1>
              </div>
              <b-form-select v-model="teacherLEA" :options="teacherLEAOptions" />
            </div>
            <div class="col-3">
              <h4><subject-tag :subject="s" v-for="s in teacherDetail.subjects" :key="s" /></h4>
            </div>
            <div class="col-3 text-right" v-if="teacherDetail.verification && (teacherDetail.hasVerifiableCourses || teacherDetail.contributingTeacher)" style="max-height: 70px;">
              <div class="col-auto font-weight-bold h5">
                <div v-if="!teacherDetail.canEdit">
                  <i class="fas fa-lock" />
                </div>
              </div>
              <span class="h5 font-weight-bold text-right">Verification Status <span class="badge badge-lg ml-1" :class="getStatus(teacherDetail.verification.status).class">{{ getStatus(teacherDetail.verification.status).description }}</span></span>
              <div style="padding-right: .3em;" class="mt-1">
                <div v-if="isTeacher && !isPrincipal && hasMissingData">
                  <div class="alert alert-warning text-center">
                    <i class="fa fa-exclamation-triangle" /> There are students with missing data.
                  </div>
                </div>
                <state-change-widget
                  v-else
                  :status="teacherDetail.verification.status"
                  :available-states="teacherStates"
                  :state-map="TeacherStateMap"
                  :transition-action="transitionAction"
                  advance-button-class="text-left"
                  :identifiers="{ lea: currentLea, id: teacherDetail.summary.uniqueEmployeeId }"
                  :can-withdraw="false"
                  :document="teacherDetail.verification"
                  style="z-index: 98;"
                />
              </div>
            </div>
          </div>

          <b-row no-gutters class="mt-3" style="margin-bottom: -2rem;" v-if="teacherDetail.hasVerifiableCourses">
            <b-col cols="4">
              <verification-progress-chart units="students" title="Student Verification Progress" :complete="completeStudents" :total="totalVerifiableStudents" />
            </b-col>
          </b-row>

          <div class="card mt-3">
            <div class="card-body card-body-secondary">
              <dl class="row">
                <dt class="col-sm-3">
                  E-mail Address
                </dt>
                <dd class="col-sm-3">
                  {{ teacherDetail.teacherEmail || '(none)' }}
                </dd>
                <dt class="col-sm-3">
                  Gender
                </dt>
                <dd class="col-sm-3">
                  {{ teacherDetail.summary.gender }}
                </dd>
                <dt class="col-sm-3">
                  Years of Experience
                </dt>
                <dd class="col-sm-3">
                  {{ teacherDetail.summary.experienceTotalYears }}
                </dd>
                <dt class="col-sm-3">
                  Race
                </dt>
                <dd class="col-sm-3">
                  {{ parseRaceData(teacherDetail.summary.raceEthnicity) }}
                </dd>
                <dt class="col-sm-3">
                  Years of Experience in District
                </dt>
                <dd class="col-sm-3">
                  {{ teacherDetail.summary.experienceDistrictYears }}
                </dd>
                <dt class="col-sm-3">
                  Degree
                </dt>
                <dd class="col-sm-3">
                  {{ teacherDetail.summary.degreeDescription }}
                </dd>
                <dt class="col-sm-3">
                  AELS Case ID
                </dt>
                <dd class="col-sm-3">
                  <aels-case-id-link :case-id="caseId" />
                </dd>
                <div class="col-sm-12 mt-3" v-if="(teacherDetail.licenses || []).length > 0">
                  <b-card-header>
                    Licenses Issued
                  </b-card-header>
                  <b-card>
                    <b-table
                      :small="true"
                      :fields="licenseFields"
                      :items="teacherDetail.licenses"
                      :tbody-tr-class="licenseRowClass">
                      <template v-slot:cell(alp)="data">
                        <i class="fas fa-check" v-if="data.value" />
                      </template>
                    </b-table>
                  </b-card>
                </div>
              </dl>
            </div>
          </div>

          <div class="card mt-3" v-if="teacherDetail.taughtCourses.length > 0">
            <b-tabs card>
              <b-tab active v-if="teacherDetail.hasVerifiableCourses">
                <template v-slot:title>
                  Verifiable Classrooms <b-badge pill>
                    {{ verifiableCourses.length }}
                  </b-badge>
                </template>
                <div class="card-body-secondary">
                  <div class="mb-2">
                    <span class="font-weight-bold mr-2">Category</span>
                    <b-form-checkbox-group v-model="categoryFilter" :options="categoryOptions" buttons button-variant="outline-info">
                      <template v-slot:default>
                        <div class="btn btn-secondary" @click="categoryFilter = []" :class="{ disabled: categoryFilter == [] }" :disabled="categoryFilter == []">
                          Clear
                        </div>    
                      </template>
                    </b-form-checkbox-group>               
                  </div>
                  <paginated-table :items="filteredVerifiableCourses" :fields="verifiableFields" :sort-asc="true" :per-page="5000" striped hover :show-filter="false" :hide-controls="true" :load-data="loading">
                    <template v-slot:cell(action)="row">
                      <router-link class="btn btn-sm btn-primary" title="View" :to="{ name: classroomRoute, params: { teacherId: teacherDetail.summary.uniqueEmployeeId, id: row.item.courseCode, section: row.item.courseSection, lea: currentLea, teacherType: row.item.teacherType, sk: row.item.sectionKey } }">
                        <i class="fas fa-eye" />
                      </router-link>
                    </template>
                    <template v-slot:cell(verificationProgress)="data">
                      <div v-if="data.item.totalStudents > 0">
                        <b-progress min="0" :max="100" style="height: 1rem;">
                          <b-progress-bar :value="data.value" />
                        </b-progress>
                        <div class="progress-overlay">
                          {{ data.value.toFixed(0) }}%
                        </div>
                      </div>
                    </template>
                    <template v-slot:head(licenseException)="data">
                      <custom-tooltip content="License Exceptions recorded in SIS" element-id="licenseExceptionHeader" :label="data.label" />
                    </template>
                    <template v-slot:cell(subject)="data">
                      <subject-tag :subject="data.value" />
                    </template>
                  </paginated-table>
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  Nonverifiable Classrooms <b-badge pill>
                    {{ nonverifiableCourses.length }}
                  </b-badge>
                </template>
                <div class="card-body-secondary">
                  <div class="mb-2">
                    <span class="font-weight-bold mr-2">Category</span>
                    <b-form-checkbox-group v-model="categoryFilter" :options="categoryOptions" buttons button-variant="outline-info">
                      <template v-slot:default>
                        <div class="btn btn-secondary" @click="categoryFilter = []" :class="{ disabled: categoryFilter == [] }" :disabled="categoryFilter == []">
                          Clear
                        </div>    
                      </template>
                    </b-form-checkbox-group>             
                  </div>
                  <paginated-table :items="filteredNonverifiableCourses" :fields="nonverifiableFields" :sort-asc="true" :per-page="5000" striped hover :show-filter="false" :hide-controls="true" :load-data="loading">
                    <template v-slot:cell(action)="row">
                      <router-link class="btn btn-sm btn-primary" title="View" :to="{ name: classroomRoute, params: { teacherId: teacherDetail.summary.uniqueEmployeeId, id: row.item.courseCode, section: row.item.courseSection, lea: currentLea, teacherType: row.item.teacherType, sk: row.item.sectionKey } }">
                        <i class="fas fa-eye" />
                      </router-link>
                    </template>
                    <template v-slot:cell(verificationProgress)="data">
                      <div v-if="data.item.totalStudents > 0">
                        <b-progress min="0" :max="100" style="height: 1rem;">
                          <b-progress-bar :value="data.value" />
                        </b-progress>
                        <div class="progress-overlay">
                          {{ data.value.toFixed(0) }}%
                        </div>
                      </div>
                    </template>
                    <template v-slot:head(licenseException)="data">
                      <custom-tooltip content="License Exceptions recorded in SIS" element-id="licenseExceptionHeader" :label="data.label" />
                    </template>
                    <template v-slot:cell(subject)="data">
                      <subject-tag :subject="data.value" />
                    </template>
                  </paginated-table>
                </div>
              </b-tab>
            </b-tabs>
          </div>

          <co-teacher-detail :teacher="teacherDetail" :lea="currentLea" :can-edit="teacherDetail.canEdit" v-if="teacherDetail.contributingTeacher" />

          <div class="mt-3" v-if="teacherDetail.hasVerifiableCourses || teacherDetail.contributingTeacher">
            <b-card-header>
              Status History
            </b-card-header>
            <b-card>
              <div class="card-body-secondary">
                <div class="p-0">
                  <change-log-history-table :change-logs="teacherDetail.verification.changeLogs || []" :state-map="TeacherStateMap" />
                </div>
              </div>
            </b-card>
          </div>
        </div>
        <div v-else>
          <loading-spinner />
        </div>
      </template>
      <template v-slot:rejected>
        <div class="alert alert-danger" role="alert">
          The teacher could not be loaded.  Possible reasons for this could include:
          <ul>
            <li>The teacher is not a teacher of record.</li>
            <li>The teacher is listed as teaching courses as a teacher of record but the courses have no enrollment.</li>
            <li>The teacher does not have a potential contributor job role.</li>
          </ul>
        </div>
      </template>
    </Promised>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { Promised } from 'vue-promised';
import LoadingSpinner from '@/components/LoadingSpinner'
import PaginatedTable from '@/components/PaginatedTable'
import VerificationProgressChart from '@/modules/rvs/components/dashboards/charts/VerificationProgressChart.vue'
import CoTeacherDetail from '@/modules/rvs/components/common/CoTeacherDetail.vue'
import AelsCaseIdLink from '@/components/AelsCaseIdLink.vue'
import CustomTooltip from '@/components/CustomTooltip.vue'
import SubjectTag from './SubjectTag.vue'
import StateChangeWidget from '@/components/workflow/StateChangeWidget.vue'
import { TeacherStateMap } from '@/modules/rvs/rvsStateMap.js'
import { parseRaceData } from '@/modules/rvs/helper.js'
import * as moment from 'moment'
import ChangeLogHistoryTable from '@/components/workflow/ChangeLogHistoryTable.vue'

export default {
  data() {
    return {
      verifiableFields: [
        { key: 'action', label: '', sortable: false },
        { key: 'courseCode', label: 'Course Code', sortable: true },
        { key: 'courseSection', label: 'Course Section', sortable: true },
        { key: 'courseDescription', label: 'Course Description', sortable: true },
        { key: 'subject', label: 'Subject', sortable: true },
        { key: 'category', label: 'Category', sortable: true },
        { key: 'licenseException', label: 'License Exception(s)' },
        { key: 'completeStudents', label: 'Complete Students', sortable: true },
        { key: 'totalVerifiableStudents', label: 'Total Verifiable Students', sortable: true },
        { key: 'verificationProgress', sortByFormatted: true, tdClass: 'progress-bar-cell', label: 'Verification Progress', sortable: true, formatter: (value, key, item) => (item.completeStudents / item.totalVerifiableStudents) * 100 }
      ],
      nonverifiableFields: [
        { key: 'action', label: '', sortable: false },
        { key: 'courseCode', label: 'Course Code', sortable: true },
        { key: 'courseSection', label: 'Course Section', sortable: true },
        { key: 'courseDescription', label: 'Course Description', sortable: true },
        { key: 'category', lable: 'Category', sortable: true },
        { key: 'licenseException', label: 'License Exception(s)' },
        { key: 'totalStudents', label: 'Total Students', sortable: true },
      ],
      licenseFields: [
        { key: 'code' },
        { key: 'alp', label: 'ALP' },
        { key: 'lastIssue', formatter: d => d ? moment(d).format('MM/DD/YYYY') : '--' },
        { key: 'expirationDate', formatter: d => d ? moment(d).format('MM/DD/YYYY') : '--' }
      ],
      loading: Promise.resolve(true),
      contributingTeacherLoading: Promise.resolve(true),
      parseRaceData,
      collapsedExceptions: true,
      categoryFilter: [],
      categoryOptions: [
        { text: 'AP', value: 'ap' },
        { text: 'IB', value: 'ib' },
        { text: 'Concurrent', value: 'cc' },
        { text: 'Other', value: 'other' },
      ],
      TeacherStateMap,
      teacherLEA: null,      
    }
  },
  components: {
    Promised,
    LoadingSpinner,
    PaginatedTable,
    VerificationProgressChart,
    CoTeacherDetail,
    AelsCaseIdLink,
    CustomTooltip,
    SubjectTag,
    StateChangeWidget,
    ChangeLogHistoryTable
  },
  computed: {   
    ...mapState('rvsModule', ['teacherDetail', 'fiscalYear', 'teacherStates']),
    ...mapGetters('globalModule', ['isPrincipal']),
    ...mapGetters('globalModule', ['employeeId', 'schoolIds']),
    classroomRoute() {
      return this.$route.meta.isTeacher ? 'Schedule/RVS/Teacher/Classroom' : 'Schedule/RVS/Teachers/Classroom'      
    },
    completeStudents() {
      return this.teacherDetail.taughtCourses.filter(course => course.subject).reduce((acc, course) => acc + course.completeStudents, 0)
    },
    totalVerifiableStudents() {
      return this.teacherDetail.taughtCourses.filter(course => course.subject).reduce((acc, course) => acc + course.totalVerifiableStudents, 0)
    },
    isTeacher() {
      return this.employeeId == this.teacherDetail.summary.uniqueEmployeeId
    },
    hasMissingData() {
      return this.completeStudents < this.totalVerifiableStudents
    },
    collapsedExceptionsClass() {
      return this.collapsedExceptions ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
    },
    caseId() {
      return this.teacherDetail.summary.caseId ? this.teacherDetail.summary.caseId.toString() : null
    },
    verifiableCourses() {
      return this.teacherDetail.taughtCourses.filter(course => course.subject != null && course.totalVerifiableStudents > 0)
    },
    filteredVerifiableCourses() {
      return this.categoryFilter.length > 0 ? this.verifiableCourses.filter(course => this.categoryFilter.includes(course.category.toLowerCase())) : this.verifiableCourses
    },
    nonverifiableCourses() {
      return this.teacherDetail.taughtCourses.filter(course => course.subject == null || course.totalVerifiableStudents <= 0)
    },
    filteredNonverifiableCourses() {
      return this.categoryFilter.length > 0 ? this.nonverifiableCourses.filter(course => this.categoryFilter.includes(course.category.toLowerCase())) : this.nonverifiableCourses
    },
    transitionAction: () => 'rvsModule/transitionTeacher',
    teacherLEAOptions() {
      return (this.teacherDetail.schoolLEAs || []).filter(s => this.leaDictionary[s]).map(s => ({ value: s, text: `${this.getSchoolName(s)} (${s})` }))
    },
    currentLea() {
      var lea = this.$route.params.lea || (this.schoolIds || [])[0] || this.selectedLea.number
      if (this.teacherDetail && this.teacherDetail.schoolLEAs && !this.teacherDetail.schoolLEAs.includes(lea) && this.leaDictionary[lea])
        lea = this.teacherDetail.schoolLEAs[0]
      return lea
    }
  },
  props: {
    
  },
  methods: {
    async reloadData() {
      const id = this.$route.params.id || this.employeeId
      const lea = this.currentLea

      if (this.fiscalYear)
        this.loading = this.$store.dispatch('rvsModule/getTeacherDetail', { lea, id })
        .then(() => {
          if (id)
            this.$store.dispatch('rvsModule/getTeacherStates', { lea, id, fiscalYear: this.fiscalYear })
        })
    },
    getSchoolName(s) {
      if (this.leaDictionary[s] && this.leaDictionary[s].name)
        return this.leaDictionary[s].name
      else return 'Unknown LEA'
    },
    getStatus(status) {
      switch(status) {
        case 0:
          return { class: 'badge-secondary', description: 'Draft' }
        case 1:
          return { class: 'badge-warning', description: 'Returned to Teacher' }
        case 100:
          return { class: 'badge-primary', description: 'Pending Review' }
        case 101:
          return { class: 'badge-success', description: 'Accepted' }
        case 999:
          return { class: 'badge-danger', description: 'Exempted from Verification' }
        default:
          return { class: 'badge-secondary', description: 'Unknown' }
      }
    },
    licenseRowClass(item, type) {
      if (!item || type !== 'row') return
      if (new Date(item.expirationDate) < new Date()) return 'table-danger'
    },
    // getCourseLicenseException(course, section) {
    //   const exception = ([...this.teacherDetail.licenseExceptions] || []).find(l => l.courseCode == course && l.courseSection == section)
    //   console.log(exception)
    //   return (exception || {}).licenseExceptionType
    // },
    updatePageLabel(text) {
      this.$store.commit('globalModule/setPageLabel', text)
    }
  },
  watch: {
    fiscalYear: {
      handler() {
        this.reloadData()
      }
    },
    selectedLea: {
      handler() {
        this.reloadData()
      }
    },
    teacherDetail: {
      handler() {
        if (this.teacherDetail && this.teacherDetail.contributingTeacher) {
          this.updatePageLabel(this.teacherDetail.contributingTeacher.role)
          this.contributingTeacherLoading = this.$store.dispatch('rvsModule/getContributorDetail', { lea: this.currentLea, id: this.teacherDetail.summary.uniqueEmployeeId, fiscalYear: this.fiscalYear, clear: true })
        }
        else if (this.teacherDetail)
        {
          this.updatePageLabel('Teacher')
        }
      }
    },
    teacherLEA: {
      handler(newVal) {
        if (newVal)
        {
          this.$router.push({ name: this.$route.name, params: { id: this.$route.params.id, lea: newVal }, query: { lea: newVal }})
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.updatePageLabel(null)
    this.reloadData()
  },
  mounted() {
    this.teacherLEA = this.currentLea
  }
}

</script>